/* Section.css */
.section {
  position: relative;
  width: 100%;
  height: 92vh; /* Full viewport height */
  display: flex;
  align-items: flex-start; /* Align items to the top */
  justify-content: center; /* Center items horizontally */
  overflow: hidden; /* Prevent overflow */
  box-shadow:
    0 4px 10px rgba(0, 0, 0, 0.5),
    0 -4px 10px rgba(0, 0, 0, 0.5);
}

.section-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover; /* Ensure the background image covers the container */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating the image */
  filter: brightness(60%);
  z-index: 0; /* Ensure it stays behind the content */
}

.section-content {
  position: relative;
  color: white;
  text-align: left;
  top: 5%; /* Adjust as needed */
  z-index: 1; /* Ensure it stays above the background */
  padding: 0 20px;
}

.link {
  color: white; /* Set link color to white */
  font-weight: bold;
  text-decoration: none; /* Optional: Remove underline */
  margin: 0 5px;
  transition: color 0.3s ease;
}

.link:hover {
  color: black;
}

.section-content h1 {
  margin: 0;
  font-size: 4rem; /* Adjust as needed */
  font-weight: bold;
}

.subtitle-item {
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 900px; /* Adjust width as needed */
  line-height: 1.8;
}

.content-item {
  font-size: 1.25rem;
  max-width: 900px; /* Adjust width as needed */
  line-height: 1.5;
}

/* Optional: Add styles for responsiveness */
@media (max-width: 960px) {
  .section-content h1 {
    font-size: 2rem;
  }

  .subtitle-item {
    font-size: 1.3rem;
  }

  .content-item {
    font-size: 1rem;
  }
}
