.Photocards {
  position: relative;
  padding: 2rem;
  background-image: linear-gradient(90deg, #ffffff 0%, #f3efef 100%);
  z-index: 1;
}

.Photocards::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/oc12.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(60%);
  z-index: -1;
}

.Schedcards {
  position: relative;
  padding: 2rem;
  background-image: linear-gradient(90deg, #ffffff 0%, #f3efef 100%);
  z-index: 1;
}

.Schedcards::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/oc16.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(60%);
  z-index: -1;
}
.Homecards {
  position: relative;
  padding: 2rem;
  background-image: linear-gradient(90deg, #ffffff 0%, #f3efef 100%);
  z-index: 1;
}

.Homecards::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/oc10.JPG");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(60%);
  z-index: -1;
}

.cards__title {
  position: relative; /* Fixes the title at the top */
  transform: translateY(-15px);
  font-size: 4rem; /* Adjust as needed */
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 0px;
}
.cards__subtitle {
  text-align: center;
  transform: translateY(-15px);
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.8;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 24px;
}

.cards__wrapper {
  position: relative;
}

.cards__items {
  margin: 15px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Smooth transition for growth */
}

.cards__item:hover {
  transform: scale(1.05); /* Grow card on hover */
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  background: #333333;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  height: auto; /* Allow it to expand based on image height */
  overflow: hidden; /* Prevent cropping */
}

.cards__item__img {
  display: block;
  width: 100%;
  max-width: 80vh;
  height: auto; /* Maintain aspect ratio */
  max-height: 80vh; /* Limit image height to viewport height */
  object-fit: contain; /* Ensure the entire image is visible */
  transition: all 0.2s linear; /* Smooth transition for image effects */
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media (max-width: 960px) {
  .cards__title {
    font-size: 2rem;
  }
  .cards__item__info {
    padding: 10px 10px 10px;
  }
  .cards__item__text {
    color: #fff;
    font-size: 12px;
    line-height: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
