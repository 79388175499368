/* General Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f4f4f4; /* Subtle background color for contrast */
}

/* Navbar Styles */
.navbar {
  background: linear-gradient(90deg, #ffffff 0%, #f3efef 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f3efef;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.navbar:hover::before {
  opacity: 1;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  position: relative; /* Keep this if necessary for child positioning */
  z-index: 1;
}

.navbar-logo-image {
  height: 90px;
  width: auto;
}

.navbar-logo {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

.navbar-logo:hover {
  transform: scale(1.05);
}

.navbar-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #000;
  padding: 0 3rem;
  position: relative;
}

/* Menu Icon */
.menu-icon {
  display: none;
}

/* Navigation Menu */
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin-right: 20px;
}

.nav-item {
  margin: 0 15px;
}

.nav-links {
  color: #000;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  transition:
    color 0.3s ease,
    border-bottom 0.3s ease; /* Added transition for border-bottom */
  border-bottom: 2px solid transparent; /* Initial border-bottom */
}

.nav-links:hover {
  color: #444;
  border-bottom: 2px solid #444; /* Changed border-bottom color and thickness on hover */
}

/* Mobile Styles */
.nav-links-mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    background: #ffffff;
    transition: all 0.3s ease;
  }
  .nav-menu.active {
    left: 0;
    z-index: 1;
  }

  .navbar-title {
    font-size: 1rem;
  }

  .nav-item {
    margin: 0;
  }

  .nav-links {
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: block;
  }

  .nav-links:hover {
    background-color: #f3f3f3;
    color: #444;
  }

  .navbar-logo {
    position: static;
  }

  .menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 2.5rem;
    color: #000;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .menu-icon:hover {
    color: #444;
  }

  .fa-bars,
  .fa-times {
    font-size: 2.5rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 1rem auto;
    border-radius: 4px;
    width: 80%;
    font-size: 1.5rem;
    background-color: transparent;
    color: #000;
    padding: 14px 20px;
    border: 1px solid #000;
    transition:
      background 0.3s ease,
      color 0.3s ease;
  }

  .nav-links-mobile:hover {
    background: #f3f3f3;
    color: #444;
  }
}
