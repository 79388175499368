.footer {
  background-color: #000000; /* Example background color */
  color: white;
  padding: 10px;
}

.footer-content {
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: space-between; /* Distribute space between child elements */
  width: 100%;
}

.footer-logo {
  padding: 0 20px; /* Space between the logo and the main content */
  padding-top: 10px;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.1);
}

.footer-logo-image {
  height: 150px; /* Adjust the height as needed */
}

.footer-right {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}

.footer-title {
  padding: 0 20px;
}

.divider {
  height: 150px;
  background: linear-gradient(90deg, #ffffff 0%, #f3efef 100%);
  width: 2px;
}

.footer-text {
  font-size: 1.25rem; /* Font size of the text */
  padding: 0 20px; /* Spacing around the text */
  line-height: 1; /* Line height of the text */
}

.footer-link {
  margin-right: 12px; /* Adjust the gap as needed */
  transition:
    transform 0.3s ease,
    color 0.3s ease;
  display: inline-block;
}

.footer-link:hover {
  color: #f3efef; /* Change color on hover */
  transform: scale(1.1);
}

.InstaIcon {
  color: white; /* Instagram color */
  transition: color 0.3s ease;
}

.InstaIcon:hover {
  color: #e4405f; /* Instagram color */
}

.FbIcon {
  color: white; /* Facebook color */
  transition: color 0.3s ease;
  transform: scale(0.9);
}

.FbIcon:hover {
  color: #3b5998; /* Facebook color */
}

@media (max-width: 960px) {
  .footer-content {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the left */
  }

  .footer-logo-image {
    height: 100px; /* Reduce height for smaller screens */
  }

  .footer-title {
    padding-top: 10px;
  }

  .divider {
    height: 50px; /* Reduce height */
  }
}
