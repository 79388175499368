* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}

body {
  background-color: linear-gradient(90deg, #ffffff 0%, #f3efef 100%);
  color: rgb(255, 255, 255);
}
